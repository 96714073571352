<template>
  <div class="layout-wrapper">
    <TheHeader :only-logo="isBrand(CUSTOM_APPEARANCE.MBH)" />

    <div class="is-flex is-justify-content-center is-hidden-touch mt-3">
      <template v-if="isBrand(CUSTOM_APPEARANCE.MBH)">
        <div class="usp-element mr-6">
          <b-icon icon="globe" type="is-white" class="mr-2"></b-icon>
          <span class="has-text-white">{{
            $t("af:header.title.mbh_usp_1")
          }}</span>
        </div>
        <div class="usp-element">
          <b-icon icon="pen" type="is-white" class="mr-2"></b-icon>
          <span class="has-text-white">{{
            $t("af:header.title.mbh_usp_2")
          }}</span>
        </div>
      </template>
      <template v-else>
        <div class="usp-element">
          <b-icon
            icon-pack="fa"
            icon="check-double"
            type="is-white"
            class="mr-2"
          ></b-icon>

          <span class="has-text-white">{{ $t("af:header.title.usp_1") }}</span>
        </div>
        <div class="usp-element">
          <b-icon
            icon-pack="fa"
            icon="check-double"
            type="is-white"
            class="mr-2"
          ></b-icon>

          <span class="has-text-white">{{ $t("af:header.title.usp_2") }}</span>
        </div>
        <div class="usp-element">
          <b-icon
            icon-pack="fa"
            icon="check-double"
            type="is-white"
            class="mr-2"
          ></b-icon>

          <span class="has-text-white">{{ $t("af:header.title.usp_3") }}</span>
        </div>
      </template>
    </div>

    <div class="af-container">
      <div class="columns">
        <div v-if="showSelectedOffer" class="column offer-tile" :class="{ hide: !hasDefaultOffer }">
          <SelectedOffer v-if="hasDefaultOffer" />
        </div>

        <div class="column action-tile">
          <slot />
        </div>
      </div>
    </div>

    <TheFooter />
  </div>
</template>

<script>
import { CUSTOM_APPEARANCE } from "@/utils/const";
import { isBrand } from "@/utils/util";
import { validateLandingParams } from "../utils/landingParamUtil";

export default {
  name: "AppLayoutWelcome",
  methods: { isBrand },
  components: {
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
    SelectedOffer: () => import("@/components/SelectedOffer"),
  },
  data() {
    return {
      showSelectedOffer: false,
    };
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    hasDefaultOffer() {
      return !!this.$route.query.offer;
    },
  },
  async created() {
    try {
      await this.$store.dispatch("offers/fetchOfferPreview", this.$route.query.offer);
      this.showSelectedOffer = validateLandingParams(this.$route.query);
    } catch {
      this.showSelectedOffer = false;
    }
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .af-container {
    flex-grow: 1;
    width: 100%;
    margin: 0 auto;
  }
}

.usp-element {
  padding: 10px;
}

.mbh {
  .usp-element {
    display: flex;
    align-items: center;
  }
}

.columns {
  margin: 0;
}

.columns:last-child {
  margin-bottom: initial;
}

.column {
  padding: 0;
}

.offer-tile {
  background-image: url("../assets/images/background-sport-car.png");
  height: 40vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.action-tile {
  margin-top: -16px;
}

.mbh {
  .offer-tile {
    background-image: url("../assets/images/mbh_mobile_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@media only screen and (min-width: 769px) {
  .layout-wrapper {
    background-image: url("../assets/images/background-sport-car.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .af-container {
    .columns {
      margin-top: 4rem;
      padding: 2rem;
      justify-content: center;

      .offer-tile {
        max-width: 400px;
        background-image: none;
        margin-right: 2rem;
        height: initial;
      }

      .action-tile {
        max-width: 400px;
        margin-top: initial;
      }
    }
  }

  .hide {
    display: none;
  }

  .mbh {
    .layout-wrapper {
      background-image: url("../assets/images/mbh_desktop_bg.png");
    }
  }
}
</style>
